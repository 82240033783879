import { history } from "../../../history"
import API from "../../../API"
import {toast} from "react-toastify";

export const loginWithJWT = user => {
  return dispatch => new Promise((resolve, reject) => {
    API
      .post("/api/login", {
        email: user.email,
        password: user.password
      })
      .then(response => {
        let loggedInUser, userRole, AccessToken, feedbackQuestions

        if (response.data) {
          loggedInUser = response.data.user
          userRole = response.data.user.access.name
          AccessToken = response.data.access_token
          feedbackQuestions = response.data.feedbackQuestions

          dispatch({
            type: "LOGIN_WITH_JWT",
            payload: { loggedInUser, userRole, AccessToken, feedbackQuestions }
          })

          // history.push("/")
          return resolve();
        }
      })
      .catch(err => {
        console.log(err)
        toast.error("Wrong Email or Password", {
          position: "bottom-right",
        });
        return reject(err);
      })
  });
}

export const updateLoginWithJWTFeedbackQuestions = (feedbackQuestions) => {
  return dispatch => {
    dispatch({ type: "UPDATE_FEEDBACK_QUESTIONS", payload: {feedbackQuestions } })
  }
}

export const updateLoggedInUser = (loggedInUser) => {
  return dispatch => {
    dispatch({ type: "UPDATE_LOGGED_IN_USER", payload: {loggedInUser } })
  }
}

export const logoutWithJWT = () => {
  return dispatch => {
    let loggedInUser = {}
    let userRole = {}
    dispatch({ type: "LOGOUT_WITH_JWT", payload: { loggedInUser, userRole } })
    history.push("/login")
  }
}

export const updateReadyConfirmOrdersChangeWithJWT = user => {
  return dispatch => new Promise((resolve, reject) => {
    API
      .put(`api/logistic/users/${user.id}`, {
        ready_to_confirm_orders: !Boolean(user.ready_to_confirm_orders)
      })
      .then(response => {
        let loggedInUser = response.data.data
        dispatch({
          type: "UPDATE_LOGGED_IN_USER",
          payload: {loggedInUser}
        })
        return resolve(response);
      })
      .catch(err => {
        return reject(err);
      })
  });
}

