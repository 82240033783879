const initialState = {
  data: [],
}

const countryZoneReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_COUNTRY_ZONE":
      return {
        ...state,
        data: action.data,
      }
    case "UPDATE_COUNTRY_ZONE":
      let newData = []
      state.data.forEach(function (currentValue, index, array) {
        if(currentValue.id === action.data.id){
          newData.push(action.data);
        }
        else {
          newData.push(currentValue);
        }
      })
      return {...state, data: newData}
    default:
      return state
  }
}

export default countryZoneReducer
