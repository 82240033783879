import React from "react"
import * as Icon from "react-feather"

const navigationConfig = [
  {
    id: "dashboard",
    title: "Dashboard",
    type: "item",
    visibility: "hidden",
    permissions: ["admin", "super_agent", "agent", "super_logistic", "logistic"],
    navLink: "/"
  },
  {
    id: "invoice",
    title: "Invoice",
    type: "item-hidden",
    permissions: ["admin", "super_agent"],
    navLink: "/invoice"
  },
  {
    type: "groupHeader",
    groupTitle: "Agents",
    permissions: ["admin"]
  },
  {
    id: "agents-database",
    title: "Agents database",
    type: "item",
    icon: <Icon.Users size={20}/>,
    permissions: ["admin"],
    navLink: "/agents-database"
  },
  {
    id: "super-agent-performance",
    title: "Super Agent performance",
    type: "item",
    icon: <Icon.Activity size={20}/>,
    permissions: ["admin"],
    navLink: "/super-agent-performance"
  },
  {
    id: "contact-agent",
    title: "Contact Agent",
    type: "item",
    icon: <Icon.Mail size={20}/>,
    permissions: ["admin"],
    navLink: "/contact-agent"
  },
  {
    id: "agent-commissions",
    title: "Agent commissions",
    type: "item",
    icon: <Icon.Percent size={20}/>,
    permissions: ["admin"],
    navLink: "/agent-commissions"
  },
  {
    id: "leads-from-agent",
    title: "Leads from agent",
    type: "item",
    icon: <Icon.Filter size={20}/>,
    permissions: ["admin"],
    navLink: "/admin-leads-from-agent"
  },
  {
    id: "food-court-leads",
    title: "Food court leads",
    type: "item",
    icon: <Icon.Sunset size={20}/>,
    permissions: ["admin"],
    navLink: "/admin-food-court-leads"
  },
  {
    id: "admin-food-court-quotation",
    title: "Food Court Quotation",
    type: "item-hidden",
    // visibility: "hidden",
    permissions: ["admin"],
    navLink: "/admin-food-court-quotation"
  },
  {
    id: "admin-food-court-preview-edit",
    title: "Food Court Preview/Edit",
    type: "item-hidden",
    // visibility: "hidden",
    permissions: ["admin"],
    navLink: "/admin-food-court-preview-edit"
  },
  {
    id: "quotation",
    title: "Quotation",
    type: "item",
    icon: <Icon.Edit size={20}/>,
    permissions: ["admin"],
    navLink: "/admin-quotation"
  },
  {
    id: "free-quotation",
    title: "Free Quotation",
    type: "item",
    icon: <Icon.Edit3 size={20}/>,
    permissions: ["admin"],
    navLink: "/free-quotation"
  },
  {
    id: "trainings",
    title: "Trainings",
    type: "item",
    icon: <Icon.Youtube size={20}/>,
    permissions: ["admin"],
    navLink: "/admin-trainings"
  },
  {
    id: "edit-feedback-questionnaire",
    title: "Feedback questionnaire",
    type: "item",
    icon: <Icon.PhoneIncoming size={20}/>,
    permissions: ["admin"],
    navLink: "/edit-feedback-questionnaire"
  },
  {
    type: "groupHeader",
    groupTitle: "Client",
    permissions: ["admin"]
  },
  {
    id: "clients-database",
    title: "Clients database",
    type: "item",
    icon: <Icon.Database size={20}/>,
    permissions: ["admin"],
    navLink: "/clients-database"
  },
  {
    id: "ordering-hardware",
    title: "Ordering hardware",
    type: "item-hidden",
    permissions: ["admin", "super_agent"],
    navLink: "/ordering-hardware/:id"
  },
  {
    id: "invoice-hardware",
    type: "item-hidden",
    permissions: ["admin", "super_agent"],
    navLink: "/invoice-hardware/:id"
  },
  {
    id: "extension-hardware",
    type: "item-hidden",
    permissions: ["admin", "super_agent"],
    navLink: "/extension-hardware/:uuid"
  },
  {
    id: "extension-standard",
    type: "item-hidden",
    permissions: ["admin", "super_agent"],
    navLink: "/extension-standard/:uuid"
  },
  {
    id: "clients-invoice",
    title: "Clients Invoice",
    type: "item",
    icon: <Icon.File size={20}/>,
    permissions: ["admin"],
    navLink: "/clients-invoice"
  },
  {
    id: "client-set-up-to-do-list",
    title: "Client Set Up To Do List",
    type: "item",
    icon: <Icon.UserCheck size={20}/>,
    permissions: ["admin"],
    navLink: "/admin-client-set-up-to-do-list"
  },
  {
    id: "clients-payment",
    title: "Clients Payment",
    type: "item",
    icon: <Icon.PieChart size={20}/>,
    permissions: ["admin"],
    navLink: "/clients-payment"
  },
  {
    type: "groupHeader",
    groupTitle: "Internal",
    permissions: ["admin"]
  },
  {
    id: "pricing",
    title: "Pricing",
    type: "item",
    icon: <Icon.DollarSign size={20}/>,
    permissions: ["admin"],
    navLink: "/pricing",
  },
  {
    id: "country-region-database",
    title: "Country database",
    type: "item",
    icon: <Icon.Map size={20}/>,
    permissions: ["admin"],
    navLink: "/country-database"
  },
  // super_agent, agent
  {
    type: "groupHeader",
    groupTitle: "Main",
    permissions: ["super_agent", "agent"]
  },
  {
    id: "profile",
    title: "Profile",
    type: "item",
    icon: <Icon.User size={20}/>,
    permissions: ["super_agent", "agent"],
    navLink: "/profile"
  },
  {
    id: "trainings",
    title: "Training Materials",
    type: "item",
    icon: <Icon.Youtube size={20}/>,
    permissions: ["super_agent", "agent"],
    navLink: "/trainings"
  },
  {
    id: "your-clients",
    title: "Your clients",
    type: "item",
    icon: <Icon.Users size={20}/>,
    permissions: ["super_agent"],
    navLink: "/your-clients"
  },
  {
    id: "leads-from-agent",
    title: "Leads from agent",
    type: "item",
    icon: <Icon.Filter size={20}/>,
    permissions: ["super_agent"],
    navLink: "/leads-from-agent"
  },

  {
    id: "your-leads",
    title: "Your leads",
    type: "item",
    icon: <Icon.Filter size={20}/>,
    permissions: ["agent"],
    navLink: "/your-leads"
  },
  {
    id: "send-lead-to-sa",
    title: "Send Lead to SA",
    type: "item",
    icon: <Icon.Mail size={20}/>,
    permissions: ["agent"],
    navLink: "/send-lead-to-sa"
  },
  {
    id: "quotation",
    title: "Quotation",
    type: "item",
    icon: <Icon.Edit size={20}/>,
    permissions: ["super_agent"],
    navLink: "/quotation"
  },
  {
    id: "client-set-up-to-do-list",
    title: "Client Set Up To Do List",
    type: "item",
    icon: <Icon.UserCheck size={20}/>,
    permissions: ["super_agent"],
    navLink: "/client-set-up-to-do-list"
  },
  {
    id: "your-commissions",
    title: "Your commissions",
    type: "item",
    icon: <Icon.Percent size={20}/>,
    permissions: ["super_agent", "agent"],
    navLink: "/your-commissions"
  },
  {
    id: "feedback-questionnaire",
    title: "Feedback questionnaire",
    type: "item",
    icon: <Icon.PhoneCall size={20}/>,
    permissions: ["super_agent"],
    feedbackQuestions: true,
    navLink: "/feedback-questionnaire"
  },
  {
    id: "super-agent-support",
    title: "Support",
    type: "external-link",
    icon: <Icon.HelpCircle size={20}/>,
    permissions: ["super_agent"],
    //navLink: "/support"
    navLink: "https://e-wand.atlassian.net/servicedesk/customer/portal/2"
  },
  {
    id: "download-customer-apk",
    title: "Demo app",
    type: "external-link",
    icon: <Icon.Download size={20}/>,
    permissions: ["super_agent"],
    navLink: `${process.env.REACT_APP_BACK_URL}apks/qa/qa-super-agent-without-geo-customer.apk`
  },
  // super_logistic, logistic
  {
    type: "groupHeader",
    groupTitle: "Main",
    permissions: ["super_logistic", "logistic"]
  },
  {
    id: "new-orders",
    title: "New orders",
    type: "item",
    icon: <Icon.Filter size={20}/>,
    permissions: ["super_logistic", "logistic"],
    navLink: "/new-orders"
  },
  {
    id: "new-translations",
    title: "Translations",
    type: "item",
    icon: <Icon.FileText size={20}/>,
    permissions: ["super_logistic"],
    navLink: "/new-translations"
  },
  {
    id: "archives",
    title: "Archives",
    type: "item",
    icon: <Icon.Archive size={20}/>,
    permissions: ["super_logistic", "logistic"],
    navLink: "/orders-archives"
  },
  {
    id: "create-new-logistics",
    title: "Create new logistics",
    // type: "item",
    type: "item-hidden",
    icon: <Icon.UserPlus size={20}/>,
    permissions: ["super_logistic"],
    navLink: "/create-new-logistics"
  },
  {
    id: "logistics-database",
    title: "Logistics database",
    type: "item",
    icon: <Icon.Users size={20}/>,
    permissions: ["super_logistic"],
    navLink: "/logistics-database"
  },
  {
    id: "stock-management",
    title: "Stock management",
    type: "item",
    icon: <Icon.ShoppingCart size={20}/>,
    permissions: ["super_logistic"],
    navLink: "/stock-management"
  },
  {
    id: "stock-history",
    title: "Stock history",
    type: "item-hidden",
    permissions: ["super_logistic"],
    navLink: "/stock-history/:user_id"
  },
  {
    id: "stock-arriving",
    title: "Stock arriving",
    type: "item",
    icon: <Icon.ShoppingCart size={20}/>,
    permissions: ["logistic"],
    navLink: "/stock-arriving"
  },
  {
    id: "courier-companies",
    title: "Courier companies",
    type: "item",
    icon: <Icon.Truck size={20}/>,
    permissions: ["super_logistic"],
    navLink: "/courier-companies"
  },
]

export default navigationConfig
