import React from "react"
import {
  UncontrolledDropdown,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Badge
} from "reactstrap"
import Toggle from "react-toggle"
import * as Icon from "react-feather"
import {history} from "../../../history"
import {IntlContext} from "../../../utility/context/Internationalization"
import {FormattedMessage} from "react-intl";

import "react-toggle/style.css"
import "../../../assets/scss/plugins/forms/switch/react-toggle.scss"

const handleNavigation = (e, path) => {
  e.preventDefault()
  history.push(path)
}

const UserDropdown = props => {
  return (
    <DropdownMenu right>
      {(props.userObject.login.userRole === 'agent' || props.userObject.login.userRole === 'super_agent') && (
        <DropdownItem
          tag="a"
          href="#"
          onClick={e => handleNavigation(e, "/profile")}
        >
          <Icon.User size={14} className="mr-50"/>
          <span className="align-middle"><FormattedMessage id="Profile"/></span>
        </DropdownItem>
      )}
      {(props.userObject.login.userRole === 'admin') && (
        <DropdownItem
          tag="a"
          href="#"
          onClick={e => handleNavigation(e, "/agents-database")}
        >
          <Icon.Users size={14} className="mr-50"/>
          <span className="align-middle"><FormattedMessage id="Agents database"/></span>
        </DropdownItem>
      )}
      {(props.userObject.login.userRole === 'super_logistic' || props.userObject.login.userRole === 'logistic') && (
        <DropdownItem
          tag="a"
          href="#"
          onClick={e => handleNavigation(e, "/new-orders")}
        >
          <Icon.Users size={14} className="mr-50"/>
          <span className="align-middle"><FormattedMessage id="New orders"/></span>
        </DropdownItem>
      )}
      <DropdownItem divider/>
      <DropdownItem
        tag="a"
        href="/login"
        onClick={e => {
          e.preventDefault()
          const provider = props.loggedInWith
          if (provider !== null) {
            if (provider === "jwt") {
              return props.logoutWithJWT()
            }
          } else {
            history.push("/login")
          }
        }}
      >
        <Icon.Power size={14} className="mr-50"/>
        <span className="align-middle"><FormattedMessage id="Log Out"/></span>
      </DropdownItem>
    </DropdownMenu>
  )
}

class NavbarUser extends React.PureComponent {
  state = {
    langDropdown: false,
  }

  componentDidMount() {

  }


  handleLangDropdown = () =>
    this.setState({langDropdown: !this.state.langDropdown})

  render() {
    return (
      <ul className="nav navbar-nav navbar-nav-user float-right">
        {(this.props.userObject.login.userRole === "logistic") && (
          <li className="nav-item toggle-ready-confirm-orders">
            <label className="react-toggle-wrapper">
              <Toggle
                defaultChecked={Boolean(this.props.userObject.login.loggedInUser.ready_to_confirm_orders)}
                onChange={() => this.props.handleReadyConfirmOrdersChange(this.props.userObject.login.loggedInUser)}
                className="switch-danger switch-md"
              />
            </label>
            <span className="label-text d-sm-inline-block d-none"><FormattedMessage id="Available / Busy"/></span>
          </li>
        )}
        <IntlContext.Consumer>
          {context => {
            let langArr = {
              "en": "English",
              "fr": "Français",
              "es": "Spanish",
              "pt": "Português",
              "pl": "Polish",
              "sw": "Swahili",
            }
            return (
              <Dropdown
                tag="li"
                className="dropdown-language nav-item"
                isOpen={this.state.langDropdown}
                toggle={this.handleLangDropdown}
                data-tour="language"
              >
                <DropdownToggle
                  tag="a"
                  className="nav-link"
                >
                  <span className="d-sm-inline-block d-none text-capitalize align-middle ml-50">
                  <Icon.Globe className="globe-language-svg" size={20}/>{langArr[context.state.locale]}
                  </span>
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem
                    tag="a"
                    onClick={e => context.switchLanguage("fr")}
                  >
                    <span className="ml-1">Français</span>
                  </DropdownItem>
                  <DropdownItem
                    tag="a"
                    onClick={e => context.switchLanguage("en")}
                  >
                    <span className="ml-1">English</span>
                  </DropdownItem>
                  <DropdownItem
                    tag="a"
                    onClick={e => context.switchLanguage("es")}
                  >
                    <span className="ml-1">Español</span>
                  </DropdownItem>
                  <DropdownItem
                    tag="a"
                    onClick={e => context.switchLanguage("pt")}
                  >
                    <span className="ml-1">Português</span>
                  </DropdownItem>
                  {/*<DropdownItem*/}
                  {/*  tag="a"*/}
                  {/*  onClick={e => context.switchLanguage("ro")}*/}
                  {/*>*/}
                  {/*  <span className="ml-1">Romanian</span>*/}
                  {/*</DropdownItem>*/}
                </DropdownMenu>
              </Dropdown>
            )
          }}
        </IntlContext.Consumer>
        <UncontrolledDropdown
          tag="li"
          className="dropdown-notification nav-item"
        >
          <DropdownToggle tag="a" className="nav-link nav-link-label">
            <Icon.Bell size={21}/>
            <Badge pill color="primary" className="badge-up">
              {" "}
              0{" "}
            </Badge>
          </DropdownToggle>
        </UncontrolledDropdown>
        <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
          <DropdownToggle tag="a" className="nav-link dropdown-user-link">
            <div className="user-nav d-sm-flex d-none">
              <span className="user-name text-bold-600">
                {this.props.userName}
              </span>
              <span className="user-status">{this.props.userRole}</span>
            </div>
            <span data-tour="user">
              <img
                src={this.props.userImg}
                className="round"
                height="40"
                width="40"
                alt="avatar"
              />
            </span>
          </DropdownToggle>
          <UserDropdown {...this.props} />
        </UncontrolledDropdown>
      </ul>
    )
  }
}

export default NavbarUser
