export const login = (state = {}, action) => {
  switch (action.type) {
    case "LOGIN_WITH_EMAIL": {
      return { ...state, values: action.payload }
    }
    case "LOGIN_WITH_FB": {
      return { ...state, values: action.payload }
    }
    case "LOGIN_WITH_TWITTER": {
      return { ...state, values: action.payload }
    }
    case "LOGIN_WITH_GOOGLE": {
      return { ...state, values: action.payload }
    }
    case "LOGIN_WITH_GITHUB": {
      return { ...state, values: action.payload }
    }
    case "LOGIN_WITH_JWT": {
      const { AccessToken, userRole, loggedInUser, feedbackQuestions } = action.payload;
      localStorage.setItem('access_token', AccessToken);
      localStorage.setItem('userRole', userRole);
      localStorage.setItem('loggedInUser', JSON.stringify(loggedInUser));
      localStorage.setItem('feedbackQuestions', JSON.stringify(feedbackQuestions));
      localStorage.setItem('loggedInWith',  "jwt");
      return {
        ...state,
        userRole,
        loggedInUser,
        feedbackQuestions,
        isAuthenticated: true,
        loggedInWith: "jwt"
      }
    }
    case "AUTH_CHECK_JWT": {
      return {
        ...state,
        userRole: localStorage.getItem('userRole'),
        loggedInUser: JSON.parse(localStorage.getItem('loggedInUser')),
        feedbackQuestions: JSON.parse(localStorage.getItem('feedbackQuestions')),
        isAuthenticated: !!localStorage.getItem('access_token'),
        loggedInWith: "jwt"
      }
    }
    case "UPDATE_FEEDBACK_QUESTIONS": {
      const {feedbackQuestions} = action.payload;
      localStorage.setItem('feedbackQuestions', JSON.stringify(feedbackQuestions));
      return {
        ...state,
        feedbackQuestions: feedbackQuestions,
      }
    }
    case "UPDATE_LOGGED_IN_USER": {
      const {loggedInUser} = action.payload;
      localStorage.setItem('loggedInUser', JSON.stringify(loggedInUser));
      return {
        ...state,
        loggedInUser: loggedInUser,
      }
    }
    case "LOGOUT_WITH_JWT": {
      localStorage.removeItem('access_token');
      localStorage.removeItem('userRole');
      localStorage.removeItem('loggedInUser');
      localStorage.removeItem('loggedInWith');
      const { userRole, loggedInUser } = action.payload;
      return {
        ...state,
        userRole,
        loggedInUser,
        isAuthenticated: false,
        loggedInWith: ""
      }
    }
    case "LOGOUT_WITH_FIREBASE": {
      return { ...state, values: action.payload}
    }
    case "CHANGE_ROLE": {
      return { ...state, userRole: action.userRole }
    }
    default: {
      return state
    }
  }
}
