import React from "react"
import {Navbar} from "reactstrap"
import {connect} from "react-redux"
import classnames from "classnames"
import {logoutWithJWT, updateReadyConfirmOrdersChangeWithJWT} from "../../../redux/actions/auth/loginActions"
import NavbarUser from "./NavbarUser"
import userImg from "../../../assets/img/logo/logo-icon-small.png"
import NavbarBookmarks from "./NavbarBookmarks"
import SweetAlert from "react-bootstrap-sweetalert";


const UserName = props => {
  let username = ""
  if (props.user.login.loggedInUser !== undefined &&
    props.user.login.loggedInUser !== null &&
    props.user.login.loggedInWith === "jwt"
  ) {
    username = props.user.login.loggedInUser.lastName + ' ' + props.user.login.loggedInUser.firstName
  } else {
    username = "User name"
  }
  return username
}

const UserRole = props => {
  let userRole = ""
  if (props.user.login.loggedInUser !== undefined &&
    props.user.login.loggedInUser !== null &&
    props.user.login.loggedInWith === "jwt"
  ) {
    userRole = props.user.login.loggedInUser.role
  } else {
    userRole = "User role"
  }
  return userRole
}

const ThemeNavbar = props => {
  const [state, setState] = React.useState({
    successAlert: false,
    errorAlert: false,
    alertMessage: '',
  });

  const colorsArr = ["primary", "danger", "success", "info", "warning", "dark"]
  const navbarTypes = ["floating", "static", "sticky", "hidden"]

  const updateReadyConfirmOrdersChangeWithJWT = (user) => {
    props.updateReadyConfirmOrdersChangeWithJWT(user)
      .then((response) => {
        setState({...state, successAlert: true, alertMessage: 'You status changed successfully'})
      })
      .catch((err) => {
        setState({...state, errorAlert: true, alertMessage: ''})
      })
  }

  const handleAlert = (field, value) => {
    setState({...state, [field]: value})
  }

  return (
    <React.Fragment>
      <div className="content-overlay"/>
      <div className="header-navbar-shadow"/>
      <Navbar
        className={classnames(
          "header-navbar navbar-expand-lg navbar navbar-with-menu navbar-shadow",
          {
            "navbar-light": props.navbarColor === "default" || !colorsArr.includes(props.navbarColor),
            "navbar-dark": colorsArr.includes(props.navbarColor),
            "bg-primary":
              props.navbarColor === "primary" && props.navbarType !== "static",
            "bg-danger":
              props.navbarColor === "danger" && props.navbarType !== "static",
            "bg-success":
              props.navbarColor === "success" && props.navbarType !== "static",
            "bg-info":
              props.navbarColor === "info" && props.navbarType !== "static",
            "bg-warning":
              props.navbarColor === "warning" && props.navbarType !== "static",
            "bg-dark":
              props.navbarColor === "dark" && props.navbarType !== "static",
            "d-none": props.navbarType === "hidden" && !props.horizontal,
            "floating-nav":
              (props.navbarType === "floating" && !props.horizontal) || (!navbarTypes.includes(props.navbarType) && !props.horizontal),
            "navbar-static-top":
              props.navbarType === "static" && !props.horizontal,
            "fixed-top": props.navbarType === "sticky" || props.horizontal,
            "scrolling": props.horizontal && props.scrolling

          }
        )}
      >
        <div className="navbar-wrapper">
          <div className="navbar-container content">
            <div
              className="navbar-collapse d-flex justify-content-between align-items-center"
              id="navbar-mobile"
            >
              <div className="bookmark-wrapper">
                <NavbarBookmarks
                  sidebarVisibility={props.sidebarVisibility}
                  handleAppOverlay={props.handleAppOverlay}
                />
              </div>
              {props.horizontal ? (
                <div className="logo d-flex align-items-center">
                  <div className="brand-logo mr-50"></div>
                  <h2 className="text-primary brand-text mb-0">Vuexy</h2>
                </div>
              ) : null}
              <NavbarUser
                handleAppOverlay={props.handleAppOverlay}
                changeCurrentLang={props.changeCurrentLang}
                userName={<UserName {...props} />}
                userRole={<UserRole {...props} />}
                userImg={userImg}
                userObject={props.user}
                loggedInWith={
                  props.user !== undefined &&
                  props.user.login !== undefined
                    ? props.user.login.loggedInWith
                    : null
                }
                logoutWithJWT={props.logoutWithJWT}
                handleReadyConfirmOrdersChange={(user) => updateReadyConfirmOrdersChangeWithJWT(user)}
              />
            </div>
          </div>
        </div>
      </Navbar>
      <SweetAlert
        success title="Success"
        show={state.successAlert}
        onConfirm={() => handleAlert("successAlert", false)}
      >
        <p className="sweet-alert-text">{state.alertMessage}</p>
      </SweetAlert>
      <SweetAlert
        error title="Error"
        show={state.errorAlert}
        onConfirm={() => handleAlert("errorAlert", false)}
      >
        <p className="sweet-alert-text">{state.alertMessage}</p>
      </SweetAlert>
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  return {
    user: state.auth
  }
}

export default connect(mapStateToProps, {logoutWithJWT, updateReadyConfirmOrdersChangeWithJWT})(ThemeNavbar)
