const initialState = {
  data: [
    {value: "Andorra", label: "Andorra"},
    {value: "Bolivia", label: "Bolivia"},
    {value: "Bahrain", label: "Bahrain"},
    {value: "Armenia", label: "Armenia"},
    {value: "American Samoa", label: "American Samoa"},
    {value: "Aruba", label: "Aruba"},
    {value: "Belize", label: "Belize"},
    {value: "Anguilla", label: "Anguilla"},
    {value: "Barbados", label: "Barbados"},
    {value: "Grenada", label: "Grenada"},
    {value: "United Arab Emirates", label: "United Arab Emirates"},
    {value: "Switzerland", label: "Switzerland"},
    {value: "Burundi", label: "Burundi"},
    {value: "Democratic Republic of the Congo", label: "Democratic Republic of the Congo"},
    {value: "Benin", label: "Benin"},
    {value: "Greenland", label: "Greenland"},
    {value: "Angola", label: "Angola"},
    {value: "Bosnia and Herzegovina", label: "Bosnia and Herzegovina"},
    {value: "Brunei", label: "Brunei"},
    {value: "Gambia", label: "Gambia"},
    {value: "Belgium", label: "Belgium"},
    {value: "Bhutan", label: "Bhutan"},
    {value: "Bulgaria", label: "Bulgaria"},
    {value: "Saint Barthélemy", label: "Saint Barthélemy"},
    {value: "Antigua and Barbuda", label: "Antigua and Barbuda"},
    {value: "Honduras", label: "Honduras"},
    {value: "Åland", label: "Åland"},
    {value: "Republic of the Congo", label: "Republic of the Congo"},
    {value: "Bouvet Island", label: "Bouvet Island"},
    {value: "Canada", label: "Canada"},
    {value: "Colombia", label: "Colombia"},
    {value: "Afghanistan", label: "Afghanistan"},
    {value: "South Georgia and the South Sandwich Islands", label: "South Georgia and the South Sandwich Islands"},
    {value: "Azerbaijan", label: "Azerbaijan"},
    {value: "Hong Kong", label: "Hong Kong"},
    {value: "Chile", label: "Chile"},
    {value: "China", label: "China"},
    {value: "Belarus", label: "Belarus"},
    {value: "French Guiana", label: "French Guiana"},
    {value: "Cape Verde", label: "Cape Verde"},
    {value: "Austria", label: "Austria"},
    {value: "Bahamas", label: "Bahamas"},
    {value: "Botswana", label: "Botswana"},
    {value: "Argentina", label: "Argentina"},
    {value: "Central African Republic", label: "Central African Republic"},
    {value: "Dominica", label: "Dominica"},
    {value: "Heard Island and McDonald Islands", label: "Heard Island and McDonald Islands"},
    {value: "Iran", label: "Iran"},
    {value: "Italy", label: "Italy"},
    {value: "Faroe Islands", label: "Faroe Islands"},
    {value: "Brazil", label: "Brazil"},
    {value: "India", label: "India"},
    {value: "Cameroon", label: "Cameroon"},
    {value: "Iceland", label: "Iceland"},
    {value: "United Kingdom", label: "United Kingdom"},
    {value: "Costa Rica", label: "Costa Rica"},
    {value: "Guyana", label: "Guyana"},
    {value: "France", label: "France"},
    {value: "Jersey", label: "Jersey"},
    {value: "Christmas Island", label: "Christmas Island"},
    {value: "Ivory Coast", label: "Ivory Coast"},
    {value: "Germany", label: "Germany"},
    {value: "Jamaica", label: "Jamaica"},
    {value: "Cocos [Keeling] Islands", label: "Cocos [Keeling] Islands"},
    {value: "Czech Republic", label: "Czech Republic"},
    {value: "Cook Islands", label: "Cook Islands"},
    {value: "Australia", label: "Australia"},
    {value: "Albania", label: "Albania"},
    {value: "Egypt", label: "Egypt"},
    {value: "Guinea-Bissau", label: "Guinea-Bissau"},
    {value: "Bangladesh", label: "Bangladesh"},
    {value: "Guatemala", label: "Guatemala"},
    {value: "Antarctica", label: "Antarctica"},
    {value: "Curacao", label: "Curacao"},
    {value: "Cyprus", label: "Cyprus"},
    {value: "Denmark", label: "Denmark"},
    {value: "Cuba", label: "Cuba"},
    {value: "Algeria", label: "Algeria"},
    {value: "Western Sahara", label: "Western Sahara"},
    {value: "Guinea", label: "Guinea"},
    {value: "Falkland Islands", label: "Falkland Islands"},
    {value: "Guam", label: "Guam"},
    {value: "Bonaire", label: "Bonaire"},
    {value: "Djibouti", label: "Djibouti"},
    {value: "Burkina Faso", label: "Burkina Faso"},
    {value: "Eritrea", label: "Eritrea"},
    {value: "Haiti", label: "Haiti"},
    {value: "Gibraltar", label: "Gibraltar"},
    {value: "Jordan", label: "Jordan"},
    {value: "Estonia", label: "Estonia"},
    {value: "Hungary", label: "Hungary"},
    {value: "Ethiopia", label: "Ethiopia"},
    {value: "Dominican Republic", label: "Dominican Republic"},
    {value: "Bermuda", label: "Bermuda"},
    {value: "Micronesia", label: "Micronesia"},
    {value: "Greece", label: "Greece"},
    {value: "Spain", label: "Spain"},
    {value: "Guadeloupe", label: "Guadeloupe"},
    {value: "Ecuador", label: "Ecuador"},
    {value: "Guernsey", label: "Guernsey"},
    {value: "Indonesia", label: "Indonesia"},
    {value: "Georgia", label: "Georgia"},
    {value: "Ireland", label: "Ireland"},
    {value: "Ghana", label: "Ghana"},
    {value: "Israel", label: "Israel"},
    {value: "Finland", label: "Finland"},
    {value: "Equatorial Guinea", label: "Equatorial Guinea"},
    {value: "Croatia", label: "Croatia"},
    {value: "British Indian Ocean Territory", label: "British Indian Ocean Territory"},
    {value: "Fiji", label: "Fiji"},
    {value: "Iraq", label: "Iraq"},
    {value: "Isle of Man", label: "Isle of Man"},
    {value: "Gabon", label: "Gabon"},
    {value: "Monaco", label: "Monaco"},
    {value: "Liechtenstein", label: "Liechtenstein"},
    {value: "North Korea", label: "North Korea"},
    {value: "Cayman Islands", label: "Cayman Islands"},
    {value: "Saint Kitts and Nevis", label: "Saint Kitts and Nevis"},
    {value: "Cambodia", label: "Cambodia"},
    {value: "Liberia", label: "Liberia"},
    {value: "Kenya", label: "Kenya"},
    {value: "Sri Lanka", label: "Sri Lanka"},
    {value: "Japan", label: "Japan"},
    {value: "South Korea", label: "South Korea"},
    {value: "Madagascar", label: "Madagascar"},
    {value: "Kazakhstan", label: "Kazakhstan"},
    {value: "Montenegro", label: "Montenegro"},
    {value: "Moldova", label: "Moldova"},
    {value: "Luxembourg", label: "Luxembourg"},
    {value: "Lithuania", label: "Lithuania"},
    {value: "Laos", label: "Laos"},
    {value: "Kyrgyzstan", label: "Kyrgyzstan"},
    {value: "Saint Martin", label: "Saint Martin"},
    {value: "Kuwait", label: "Kuwait"},
    {value: "Kiribati", label: "Kiribati"},
    {value: "Saint Lucia", label: "Saint Lucia"},
    {value: "Lesotho", label: "Lesotho"},
    {value: "Comoros", label: "Comoros"},
    {value: "Latvia", label: "Latvia"},
    {value: "Lebanon", label: "Lebanon"},
    {value: "Libya", label: "Libya"},
    {value: "Morocco", label: "Morocco"},
    {value: "Malta", label: "Malta"},
    {value: "Maldives", label: "Maldives"},
    {value: "Norfolk Island", label: "Norfolk Island"},
    {value: "Nepal", label: "Nepal"},
    {value: "Mozambique", label: "Mozambique"},
    {value: "Mali", label: "Mali"},
    {value: "Mongolia", label: "Mongolia"},
    {value: "Mexico", label: "Mexico"},
    {value: "Macao", label: "Macao"},
    {value: "North Macedonia", label: "North Macedonia"},
    {value: "New Caledonia", label: "New Caledonia"},
    {value: "Netherlands", label: "Netherlands"},
    {value: "French Polynesia", label: "French Polynesia"},
    {value: "Portugal", label: "Portugal"},
    {value: "Somalia", label: "Somalia"},
    {value: "Namibia", label: "Namibia"},
    {value: "New Zealand", label: "New Zealand"},
    {value: "Marshall Islands", label: "Marshall Islands"},
    {value: "Northern Mariana Islands", label: "Northern Mariana Islands"},
    {value: "Nigeria", label: "Nigeria"},
    {value: "Puerto Rico", label: "Puerto Rico"},
    {value: "Malawi", label: "Malawi"},
    {value: "Palestine", label: "Palestine"},
    {value: "Saudi Arabia", label: "Saudi Arabia"},
    {value: "Nauru", label: "Nauru"},
    {value: "Martinique", label: "Martinique"},
    {value: "Poland", label: "Poland"},
    {value: "Seychelles", label: "Seychelles"},
    {value: "South Africa", label: "South Africa"},
    {value: "Saint Pierre and Miquelon", label: "Saint Pierre and Miquelon"},
    {value: "Oman", label: "Oman"},
    {value: "Panama", label: "Panama"},
    {value: "Solomon Islands", label: "Solomon Islands"},
    {value: "Mauritius", label: "Mauritius"},
    {value: "Mauritania", label: "Mauritania"},
    {value: "Sweden", label: "Sweden"},
    {value: "Sudan", label: "Sudan"},
    {value: "Niger", label: "Niger"},
    {value: "French Southern Territories", label: "French Southern Territories"},
    {value: "Palau", label: "Palau"},
    {value: "Qatar", label: "Qatar"},
    {value: "Slovenia", label: "Slovenia"},
    {value: "Norway", label: "Norway"},
    {value: "Romania", label: "Romania"},
    {value: "Montserrat", label: "Montserrat"},
    {value: "Myanmar [Burma]", label: "Myanmar [Burma]"},
    {value: "Sint Maarten", label: "Sint Maarten"},
    {value: "Tokelau", label: "Tokelau"},
    {value: "United States", label: "United States"},
    {value: "Nicaragua", label: "Nicaragua"},
    {value: "Peru", label: "Peru"},
    {value: "Russia", label: "Russia"},
    {value: "Paraguay", label: "Paraguay"},
    {value: "Tuvalu", label: "Tuvalu"},
    {value: "Thailand", label: "Thailand"},
    {value: "El Salvador", label: "El Salvador"},
    {value: "Singapore", label: "Singapore"},
    {value: "Malaysia", label: "Malaysia"},
    {value: "Serbia", label: "Serbia"},
    {value: "Rwanda", label: "Rwanda"},
    {value: "Niue", label: "Niue"},
    {value: "San Marino", label: "San Marino"},
    {value: "Turks and Caicos Islands", label: "Turks and Caicos Islands"},
    {value: "East Timor", label: "East Timor"},
    {value: "Philippines", label: "Philippines"},
    {value: "São Tomé and Príncipe", label: "São Tomé and Príncipe"},
    {value: "Saint Helena", label: "Saint Helena"},
    {value: "Pakistan", label: "Pakistan"},
    {value: "Turkmenistan", label: "Turkmenistan"},
    {value: "Tonga", label: "Tonga"},
    {value: "Ukraine", label: "Ukraine"},
    {value: "Slovakia", label: "Slovakia"},
    {value: "Papua New Guinea", label: "Papua New Guinea"},
    {value: "Tanzania", label: "Tanzania"},
    {value: "Pitcairn Islands", label: "Pitcairn Islands"},
    {value: "Sierra Leone", label: "Sierra Leone"},
    {value: "Venezuela", label: "Venezuela"},
    {value: "Syria", label: "Syria"},
    {value: "Trinidad and Tobago", label: "Trinidad and Tobago"},
    {value: "Zimbabwe", label: "Zimbabwe"},
    {value: "Svalbard and Jan Mayen", label: "Svalbard and Jan Mayen"},
    {value: "Togo", label: "Togo"},
    {value: "Vietnam", label: "Vietnam"},
    {value: "Senegal", label: "Senegal"},
    {value: "Réunion", label: "Réunion"},
    {value: "Suriname", label: "Suriname"},
    {value: "U.S. Minor Outlying Islands", label: "U.S. Minor Outlying Islands"},
    {value: "Uzbekistan", label: "Uzbekistan"},
    {value: "Vatican City", label: "Vatican City"},
    {value: "Vanuatu", label: "Vanuatu"},
    {value: "South Sudan", label: "South Sudan"},
    {value: "U.S. Virgin Islands", label: "U.S. Virgin Islands"},
    {value: "Saint Vincent and the Grenadines", label: "Saint Vincent and the Grenadines"},
    {value: "Swaziland", label: "Swaziland"},
    {value: "Tajikistan", label: "Tajikistan"},
    {value: "Samoa", label: "Samoa"},
    {value: "Turkey", label: "Turkey"},
    {value: "Chad", label: "Chad"},
    {value: "Yemen", label: "Yemen"},
    {value: "Taiwan", label: "Taiwan"},
    {value: "Uganda", label: "Uganda"},
    {value: "Mayotte", label: "Mayotte"},
    {value: "Tunisia", label: "Tunisia"},
    {value: "Uruguay", label: "Uruguay"},
    {value: "Wallis and Futuna", label: "Wallis and Futuna"},
    {value: "British Virgin Islands", label: "British Virgin Islands"},
    {value: "Kosovo", label: "Kosovo"},
    {value: "Zambia", label: "Zambia"}
  ]
}

const CountryReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_Countries":
      return {
        ...state,
      }
    default:
      return state
  }
}

export default CountryReducer
