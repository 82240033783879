const initialState = {
  data: [],
}

const feedbackQuestionnaireReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_QUESTIONNAIRE":
      return {
        ...state,
        data: action.data,
      }
    case "ADD_CATEGORY":
      return {
        ...state,
        data: action.data,
      }
    case "DELETE_CATEGORY":
      let index = state.data.findIndex(item => item.id === action.id)
      let updatedData = [...state.data]
      updatedData.splice(index, 1)
      return {
        ...state,
        data: updatedData
      }
    case "DELETE_QUESTION":
      return {
        ...state,
        data: action.data,
      }
    default:
      return state
  }
}

export default feedbackQuestionnaireReducer
