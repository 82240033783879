import React from "react"
import { IntlProvider } from "react-intl"

import messages_en from "../../assets/data/locales/en.json"
// import messages_de from "../../assets/data/locales/de.json"
import messages_fr from "../../assets/data/locales/fr.json"
import messages_es from "../../assets/data/locales/es.json"
import messages_pt from "../../assets/data/locales/pt.json"
// import messages_pl from "../../assets/data/locales/pl.json"
// import messages_sw from "../../assets/data/locales/sw.json"
// import messages_ro from "../../assets/data/locales/ro.json"

const menu_messages = {
  en: messages_en,
  // de: messages_de,
  fr: messages_fr,
  es: messages_es,
  pt: messages_pt,
  // pl: messages_pl,
  // sw: messages_sw,
  // ro: messages_ro,
}

const browser_language = Object.keys(menu_messages).includes(navigator.language) ? navigator.language : null

const Context = React.createContext()

class IntlProviderWrapper extends React.Component {

  locale_data = localStorage.getItem('language');
  state = (browser_language && this.locale_data == null) ? {locale: browser_language,messages: menu_messages[browser_language]} : (this.locale_data == null) ? {locale: "en",messages: menu_messages["en"]} : {locale: this.locale_data, messages: menu_messages[this.locale_data]}

  render() {
    const { children } = this.props
    const { locale, messages } = this.state
    return (
      <Context.Provider
        value={{
          state: this.state,
          switchLanguage: language => {
            this.setState({
              locale: language,
              messages: menu_messages[language]
            }
            )
            localStorage.setItem('language', language)
          }
        }}
      >
        <IntlProvider
          key={locale}
          locale={locale}
          messages={messages}
          defaultLocale="en"
        >
          {children}
        </IntlProvider>
      </Context.Provider>
    )
  }
}

export { IntlProviderWrapper, Context as IntlContext }
