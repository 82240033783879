import axios from 'axios';
import {store} from "./redux/storeConfig/store"
import {history} from "./history";


const defaultOptions = {
  baseURL: process.env.REACT_APP_BACK_URL,
  headers: {
    'Content-Type': 'application/json',
    'x-api-key': process.env.REACT_APP_API_KEY
  },
};

let axiosInstance = axios.create(defaultOptions);

const token = localStorage.getItem('access_token');
axiosInstance.defaults.headers.common.Authorization = `Bearer ${token}`;

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    console.log(error);
    if (error.response.data.status === 401) {
      console.log("logoutWithJWT")
      let loggedInUser = {}
      let userRole = {}
      store.dispatch({ type: "LOGOUT_WITH_JWT", payload: { loggedInUser, userRole }})
      history.push("/login")
    }
    return Promise.reject(error);
  },
);

export default axiosInstance;
