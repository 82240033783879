import { combineReducers } from "redux"
import auth from "./auth/"
import customizer from "./customizer/"
import countryReducer from "./country/"
import feedbackQuestionnaireReducer from "./feedbackQuestionnaire/"
import countryZoneReducer from "./countryZone"
import pricingReducer from "./pricing"

const rootReducer = combineReducers({
  auth: auth,
  customizer: customizer,
  country: countryReducer,
  feedbackQuestionnaire: feedbackQuestionnaireReducer,
  countryZone: countryZoneReducer,
  pricing: pricingReducer,
})

export default rootReducer
