import React from "react"
import {
  NavItem,
  NavLink,
  Button,
} from "reactstrap"
import * as Icon from "react-feather"
import {connect} from "react-redux"
import {CopyToClipboard} from "react-copy-to-clipboard";
import {toast, ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css"
import "../../../assets/scss/plugins/extensions/toastr.scss"



class NavbarBookmarks extends React.PureComponent {

  onCopyAgentEnrolment = () => {
    toast.success("Link Copied Successfully", {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 2000
    })
  }

  render() {
    let {sidebarVisibility} = this.props
    return (
      <div className="mr-auto float-left bookmark-wrapper d-flex align-items-center">
        <ul className="navbar-nav d-xl-none">
          <NavItem className="mobile-menu mr-auto">
            <NavLink
              className="nav-menu-main menu-toggle hidden-xs is-active"
              onClick={sidebarVisibility}
            >
              <Icon.Menu className="ficon"/>
            </NavLink>
          </NavItem>
        </ul>
        {(this.props.userRole === "admin") && (
          <div className="d-none d-lg-block">
            <CopyToClipboard
              onCopy={this.onCopyAgentEnrolment}
              text={process.env.REACT_APP_PUBLIC_PATH + 'agent-enrolment'}
            >
              <Button.Ripple color="primary">Agent enrolment link</Button.Ripple>
            </CopyToClipboard>
            <ToastContainer/>
          </div>
        )}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    userRole: state.auth.login.userRole
  }
}

export default connect(mapStateToProps)(NavbarBookmarks)
