const initialState = {
  pricingLoading: true,
  basePriceRestaurant: [],
  basePriceHotel: [],
  profitSharing: [],
  hardware: [],
  subscriptionHotelRoomsNumber: [],
  subscriptionHotelProductivityReportAnnualCost: [],
  subscriptionHotelLanguageOptions: [],
  subscriptionRestaurantBusinessLocation: [],
  subscriptionRestaurantBusinessLifetime: [],
  subscriptionRestaurantProductivityReport: [],
  subscriptionRestaurantLanguageOptions: [],
}

const pricing = (state = initialState, action) => {
  switch (action.type) {
    case "GET_PRICING":
      let basePriceRestaurant = [];
      let basePriceHotel = [];
      let profitSharing = [];
      let hardware = [];
      let subscriptionHotelRoomsNumber = [];
      let subscriptionHotelProductivityReportAnnualCost = [];
      let subscriptionHotelLanguageOptions = [];
      let subscriptionRestaurantBusinessLocation = [];
      let subscriptionRestaurantBusinessLifetime = [];
      let subscriptionRestaurantProductivityReport = [];
      let subscriptionRestaurantLanguageOptions = [];

      action.data.forEach(function (currentValue) {
        if(currentValue.type === "base_price" && currentValue.entity ==="restaurant" && currentValue.key ==="nb_tables_or_seats") {
          basePriceRestaurant = Object.values(JSON.parse(currentValue.options));
        } else if(currentValue.type === "base_price" && currentValue.entity === "hotel" && currentValue.key ==="stars"){
          basePriceHotel = Object.values(JSON.parse(currentValue.options));
        } else if(currentValue.type === "base_price" && currentValue.entity === "hardware" && currentValue.key ==="hardware"){
          hardware = Object.values(JSON.parse(currentValue.options));
        } else if(currentValue.type === "base_price" && currentValue.entity === "profit_sharing" && currentValue.key ==="profit_sharing"){
          profitSharing = Object.values(JSON.parse(currentValue.options));
        } else if(currentValue.type === "subscription" && currentValue.entity === "hotel" && currentValue.key ==="number_of_rooms"){
          subscriptionHotelRoomsNumber = Object.values(JSON.parse(currentValue.options))
        } else if(currentValue.type === "subscription" && currentValue.entity === "hotel" && currentValue.key ==="productivity_report_annual_cost"){
          subscriptionHotelProductivityReportAnnualCost = Object.values(JSON.parse(currentValue.options));
        } else if(currentValue.type === "subscription" && currentValue.entity === "hotel" && currentValue.key ==="language_options"){
          subscriptionHotelLanguageOptions = Object.values(JSON.parse(currentValue.options));
        } else if(currentValue.type === "subscription" && currentValue.entity === "restaurant" && currentValue.key === "business_location"){
          subscriptionRestaurantBusinessLocation = Object.values(JSON.parse(currentValue.options))
        } else if(currentValue.type === "subscription" && currentValue.entity === "restaurant" && currentValue.key ==="business_lifetime"){
          subscriptionRestaurantBusinessLifetime = Object.values(JSON.parse(currentValue.options));
        } else if(currentValue.type === "subscription" && currentValue.entity === "restaurant" && currentValue.key ==="productivity_report"){
          subscriptionRestaurantProductivityReport = Object.values(JSON.parse(currentValue.options));
        }  else if(currentValue.type === "subscription" && currentValue.entity === "restaurant" && currentValue.key ==="language_options"){
          subscriptionRestaurantLanguageOptions = Object.values(JSON.parse(currentValue.options));
        }
      })
      return {
        ...state,
        pricingLoading: false,
        basePriceRestaurant: basePriceRestaurant,
        basePriceHotel: basePriceHotel,
        profitSharing: profitSharing,
        hardware: hardware,
        subscriptionHotelRoomsNumber: subscriptionHotelRoomsNumber,
        subscriptionHotelProductivityReportAnnualCost: subscriptionHotelProductivityReportAnnualCost,
        subscriptionHotelLanguageOptions: subscriptionHotelLanguageOptions,
        subscriptionRestaurantBusinessLocation: subscriptionRestaurantBusinessLocation,
        subscriptionRestaurantBusinessLifetime: subscriptionRestaurantBusinessLifetime,
        subscriptionRestaurantProductivityReport: subscriptionRestaurantProductivityReport,
        subscriptionRestaurantLanguageOptions: subscriptionRestaurantLanguageOptions,
      }
    case "UPDATE_PRICING_BASE_PRICE_RESTAURANTS":
      let newBasePriceRestaurant = [];
      state.basePriceRestaurant.forEach(function (currentValue, index, array) {
        if(currentValue.option_key === action.data.option_key){
          newBasePriceRestaurant.push(action.data);
        }
        else {
          newBasePriceRestaurant.push(currentValue);
        }
      })
      return {...state, basePriceRestaurant: newBasePriceRestaurant}
    case "UPDATE_PRICING_BASE_PRICE_HOTELS":
      let newBasePriceHotels = [];
      state.basePriceHotel.forEach(function (currentValue, index, array) {
        if(currentValue.option_key === action.data.option_key){
          newBasePriceHotels.push(action.data);
        }
        else {
          newBasePriceHotels.push(currentValue);
        }
      })
      return {...state, basePriceHotel: newBasePriceHotels}
    case "UPDATE_PRICING_PROFIT_SHARING":
      let newProfitSharing = [];
      state.profitSharing.forEach(function (currentValue, index, array) {
        if(currentValue.option_key === action.data.option_key){
          newProfitSharing.push(action.data);
        }
        else {
          newProfitSharing.push(currentValue);
        }
      })
      return {...state, profitSharing: newProfitSharing}
    case "UPDATE_PRICING_HARDWARE":
      let newHardware = [];
      state.hardware.forEach(function (currentValue, index, array) {
        if(currentValue.option_key === action.data.option_key){
          newHardware.push(action.data);
        }
        else {
          newHardware.push(currentValue);
        }
      })
      return {...state, hardware: newHardware}
    case "UPDATE_PRICING_SUBSCRIPTION_HOTEL_ROOMS_NUMBER":
      let newSubscriptionHotelRoomsNumber = [];
      state.subscriptionHotelRoomsNumber.forEach(function (currentValue, index, array) {
        if(currentValue.option_key === action.data.option_key){
          newSubscriptionHotelRoomsNumber.push(action.data);
        }
        else {
          newSubscriptionHotelRoomsNumber.push(currentValue);
        }
      })
      return {...state, subscriptionHotelRoomsNumber: newSubscriptionHotelRoomsNumber}
    case "UPDATE_PRICING_SUBSCRIPTION_HOTEL_PRODUCTIVITY_REPORT_ANNUAL_COST":
      let newSubscriptionHotelProductivityReportAnnualCost = [];
      state.subscriptionHotelProductivityReportAnnualCost.forEach(function (currentValue, index, array) {
        if(currentValue.option_key === action.data.option_key){
          newSubscriptionHotelProductivityReportAnnualCost.push(action.data);
        }
        else {
          newSubscriptionHotelProductivityReportAnnualCost.push(currentValue);
        }
      })
      return {...state, subscriptionHotelProductivityReportAnnualCost: newSubscriptionHotelProductivityReportAnnualCost}
    case "UPDATE_PRICING_SUBSCRIPTION_HOTEL_LANGUAGE_OPTIONS":
      let newSubscriptionHotelLanguageOptions = [];
      state.subscriptionHotelLanguageOptions.forEach(function (currentValue, index, array) {
        if(currentValue.option_key === action.data.option_key){
          newSubscriptionHotelLanguageOptions.push(action.data);
        }
        else {
          newSubscriptionHotelLanguageOptions.push(currentValue);
        }
      })
      return {...state, subscriptionHotelLanguageOptions: newSubscriptionHotelLanguageOptions}
    case "UPDATE_PRICING_SUBSCRIPTION_RESTAURANT_BUSINESS_LOCATION":
      let newSubscriptionRestaurantBusinessLocation = [];
      state.subscriptionRestaurantBusinessLocation.forEach(function (currentValue, index, array) {
        if(currentValue.option_key === action.data.option_key){
          newSubscriptionRestaurantBusinessLocation.push(action.data);
        }
        else {
          newSubscriptionRestaurantBusinessLocation.push(currentValue);
        }
      })
      return {...state, subscriptionRestaurantBusinessLocation: newSubscriptionRestaurantBusinessLocation}
    case "UPDATE_PRICING_SUBSCRIPTION_RESTAURANT_BUSINESS_LIFETIME":
      let newSubscriptionRestaurantBusinessLifetime = [];
      state.subscriptionRestaurantBusinessLifetime.forEach(function (currentValue, index, array) {
        if(currentValue.option_key === action.data.option_key){
          newSubscriptionRestaurantBusinessLifetime.push(action.data);
        }
        else {
          newSubscriptionRestaurantBusinessLifetime.push(currentValue);
        }
      })
      return {...state, subscriptionRestaurantBusinessLifetime: newSubscriptionRestaurantBusinessLifetime}
    case "UPDATE_PRICING_SUBSCRIPTION_RESTAURANT_PRODUCTIVITY_REPORT":
      let newSubscriptionRestaurantProductivityReport = [];
      state.subscriptionRestaurantProductivityReport.forEach(function (currentValue, index, array) {
        if(currentValue.option_key === action.data.option_key){
          newSubscriptionRestaurantProductivityReport.push(action.data);
        }
        else {
          newSubscriptionRestaurantProductivityReport.push(currentValue);
        }
      })
      return {...state, subscriptionRestaurantProductivityReport: newSubscriptionRestaurantProductivityReport}
    case "UPDATE_PRICING_SUBSCRIPTION_RESTAURANT_LANGUAGE_OPTIONS":
      let newSubscriptionRestaurantLanguageOptions = [];
      state.subscriptionRestaurantLanguageOptions.forEach(function (currentValue, index, array) {
        if(currentValue.option_key === action.data.option_key){
          newSubscriptionRestaurantLanguageOptions.push(action.data);
        }
        else {
          newSubscriptionRestaurantLanguageOptions.push(currentValue);
        }
      })
      return {...state, subscriptionRestaurantLanguageOptions: newSubscriptionRestaurantLanguageOptions}
    default:
      return state
  }
}

export default pricing
